import type { ISpotScore } from '@/features/spot-score/spot-score-type';

import { HtmlTextWrapper } from '@/core/features';
import color from '@/core/features/styles/color';
import { classnames } from '@/core/utils/bem-classname';

interface SpotScoreBadgeProps {
    containerHeight?: number;
    containerWidth?: number;
    fontSizeScore?: 'font-size-12' | 'font-size-14';
    fontSizeText?: 'font-size-12' | 'font-size-14';
    hasSpotScore?: boolean;
    height?: number;
    hideSpotScoreText?: boolean;
    isVertical?: boolean;
    marginBottom?: number;
    scoreMarginBottom?: number;
    scoreMarginRight?: 'margin-right-5' | 'margin-right-8' | 'margin-right-10';
    spotScore: {
        htmlScoreDescription?: ISpotScore['htmlScoreDescription'];
        scoreText: ISpotScore['scoreText'];
    };
    type?: 'horizontal' | 'vertical';
    width?: number;
}

export default function SpotScoreBadge({
    containerHeight,
    containerWidth,
    fontSizeScore = 'font-size-12',
    fontSizeText = 'font-size-12',
    hasSpotScore = true,
    height = 24,
    hideSpotScoreText = false,
    isVertical,
    marginBottom = 6,
    scoreMarginBottom,
    scoreMarginRight = 'margin-right-5',
    spotScore,
    type = 'horizontal',
    width = 24,
}: SpotScoreBadgeProps) {
    const { htmlScoreDescription, scoreText } = spotScore;

    return (
        <div
            className={isVertical ? 'flex-start' : 'flex-start-center'}
            style={{
                flexDirection: type === 'horizontal' ? 'row' : 'column',
                height: containerHeight ? `${containerHeight}px` : undefined,
                marginBottom: marginBottom ? `${marginBottom}px` : undefined,
                width: containerWidth ? `${containerWidth}px` : undefined,
            }}
        >
            <div
                className={`flex-center border-radius bg-color-sunflower ${scoreMarginRight}`}
                style={{
                    backgroundColor: color('catalinaBlue'),
                    // eslint-disable-next-line no-inline-styles/no-inline-styles
                    borderBottomRightRadius: 0,
                    height: `${height}px`,
                    marginBottom: scoreMarginBottom ? `${scoreMarginBottom}px` : undefined,
                    width: `${width}px`,
                }}
            >
                <span
                    className={classnames('bold text-white text-center', fontSizeScore)}
                    style={{
                        paddingBottom: !hasSpotScore ? '3px' : '0',
                        width: width + 'px',
                    }}
                >
                    {scoreText}
                </span>
            </div>
            {!hideSpotScoreText && htmlScoreDescription && (
                <span className={classnames('bold', fontSizeText)}>
                    <HtmlTextWrapper htmlText={htmlScoreDescription} />
                </span>
            )}
        </div>
    );
}
