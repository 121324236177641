import type { IconWrapperProps } from '@/core/components/icon/icon-wrapper';
import type { SVGIconProps } from '@/core/components/icon/svg-icon';

import { IconWrapper } from '@/core/components/icon/icon-wrapper';
import SVGIcon from '@/core/components/icon/svg-icon';

export default function LayersIcon(props: SVGIconProps & IconWrapperProps) {
    return (
        <IconWrapper {...props}>
            <SVGIcon
                {...props}
                viewBox={'0 -4 48 48'}
            >
                <path
                    d={
                        'M46.6,10.3L24.3,0L2,10.3l22.3,10.3L46.6,10.3z M24.3,2.2l17.5,8.1l-17.5,8.1L6.8,10.3L24.3,2.2z M48.6,19.6l-0.8-1.8    L24.3,28.6L0.8,17.8L0,19.6l24.3,11.3L48.6,19.6z M24.3,37.9L0.8,27L0,28.9l24.3,11.3l24.3-11.3L47.8,27L24.3,37.9z'
                    }
                    vectorEffect={'non-scaling-stroke'}
                />
            </SVGIcon>
        </IconWrapper>
    );
}
