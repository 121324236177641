/* eslint-disable fp/no-mutation */
import type { BrowserStorageOptions } from '@/core/features/store/browser-storage';

import BrowserStorage from '@/core/features/store/browser-storage';

type StorageName = 'cart' | 'map_type';

/* eslint-disable fp/no-class */
export default class LocalStorage<T> extends BrowserStorage<T> {
    constructor(storeName: StorageName, options?: BrowserStorageOptions) {
        super('localStorage', storeName, options);
    }
}
