import type { BadgeType, BadgeWithTooltipType } from '@/core/components/badges/badge-type';
import type { ImageType } from '@/core/features/image-v2/image-type';
import type { LinkProps } from '@/core/features/link/link';

import React from 'react';

import { Badge, Badges, ConditionalLinkWrapper, Image } from '@/core/features';
import { useClientUrl } from '@/core/features/app/app-atoms';
import { bemModule, classnames } from '@/core/utils/bem-classname';
import MapTileIcon from '@/features/map/map-components/map-tiles/map-tile-icon';
import useMapOverlayHistoryState from '@/features/map/map-container/use-map-overlay-history';

import styles from './map-tile-wrapper.module.scss';
const bem = bemModule(styles);

type MapTileProps = {
    badge?: BadgeType | null;
    cursorStyle?: 'pointer';
    hasLivePrices?: boolean;
    height?: null | string;
    highlightedBadges?: BadgeWithTooltipType[];
    iconUrl?: string;
    image: ImageType;
    imageWidth?: number;
    isDesktopView?: boolean;
    linkType?: LinkProps['linkType'];
    linkUrl?: null | string;
    minHeight?: number;
    onImageClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    onTileLinkClick?: (event: React.MouseEvent<HTMLAnchorElement, Event>) => void;
    qaId?: string;
    subtitle?: string;
    target?: LinkProps['target'];
    title?: string;
    titleTruncation?: 'line-clamp-1' | 'line-clamp-2';
    width?: null | string;
};

export default function MapTileWrapper({
    badge,
    children,
    cursorStyle,
    hasLivePrices = false,
    height = null,
    highlightedBadges,
    iconUrl,
    image,
    imageWidth,
    isDesktopView = false,
    linkType,
    linkUrl,
    minHeight,
    onImageClick,
    onTileLinkClick,
    qaId,
    subtitle,
    target,
    title,
    titleTruncation = 'line-clamp-2',
    width = null,
}: React.PropsWithChildren<MapTileProps>) {
    const [clientUrl] = useClientUrl();
    const { closeOverlay } = useMapOverlayHistoryState();

    const onTileLinkClickWrapper = (event: React.MouseEvent<HTMLAnchorElement, Event>) => {
        // when internal linkUrl same as current page: close map overlay
        // on all other internal links: close map on next page so browser back still opens the map

        if (linkType === 'internal' && linkUrl) {
            if (linkUrl === clientUrl) {
                event.preventDefault();
                closeOverlay();
            } else {
                closeOverlay({ linkUrl });
            }
        }

        onTileLinkClick?.(event);
    };

    return (
        <ConditionalLinkWrapper
            fullWidth
            href={linkUrl}
            isFlex={true}
            linkType={linkType}
            onLinkClick={onTileLinkClickWrapper}
            target={target}
        >
            <div
                className={bem(styles.mapTileWrapper, { defaultPointer: cursorStyle !== 'pointer', hasLivePrices })}
                data-qa-id={qaId}
                style={{
                    height: height ?? (isDesktopView ? '177px' : '146px'),
                    minHeight,
                    minWidth: isDesktopView ? '375px' : undefined,
                    width: width ?? '100%',
                }}
            >
                <div
                    className={'flex relative'}
                    onClick={onImageClick}
                >
                    <div className={'flex relative'}>
                        <Image
                            alt={image.imageAlt}
                            container={'horizontal'}
                            height={200}
                            objectFit={'cover'}
                            preload={false}
                            url={image.imageUrl}
                            width={(imageWidth ?? isDesktopView) ? 105 : 120}
                        />
                        {highlightedBadges && highlightedBadges.length > 0 && (
                            <div
                                className={`${bem(styles.highlightedBadgesContainer, {
                                    isDesktopView,
                                })} flex-center`}
                                style={{ backgroundColor: highlightedBadges[0]?.backgroundColor }}
                            >
                                <Badges
                                    badges={highlightedBadges}
                                    fontSize={isDesktopView ? 14 : 12}
                                    type={'horizontal'}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {badge && (
                    <div className={styles.badge}>
                        <Badge
                            badge={badge}
                            borderRadius={0}
                        />
                    </div>
                )}
                <div className={'overflow-hidden flex-start-vertical'}>
                    {(title || subtitle || iconUrl) && (
                        <div
                            className={classnames(
                                'flex-space-between-horizontal width-100 margin-bottom-5 gap-5 padding-x-10 padding-top-10',
                                {
                                    'padding-top-15 padding-x-15': isDesktopView,
                                },
                            )}
                        >
                            {(title || subtitle) && (
                                <div className={'flex-start-vertical gap-5 overflow-hidden'}>
                                    {title && (
                                        <div
                                            className={classnames(
                                                isDesktopView
                                                    ? 'font-size-16 line-height-19 bold'
                                                    : 'font-size-14 line-height-14 bold',
                                                titleTruncation,
                                            )}
                                        >
                                            {title}
                                        </div>
                                    )}
                                    {subtitle && (
                                        <div
                                            className={classnames(
                                                isDesktopView
                                                    ? 'font-size-14 line-height-17'
                                                    : 'font-size-12 line-height-12',
                                            )}
                                        >
                                            {subtitle}
                                        </div>
                                    )}
                                </div>
                            )}
                            {iconUrl && (
                                <MapTileIcon
                                    iconSize={isDesktopView ? 35 : 32}
                                    iconUrl={iconUrl}
                                />
                            )}
                        </div>
                    )}
                    <div className={'width-100 height-100 overflow-hidden'}>{children}</div>
                </div>
            </div>
        </ConditionalLinkWrapper>
    );
}
