import type { IconWrapperProps } from '@/core/components/icon/icon-wrapper';
import type { SVGIconProps } from '@/core/components/icon/svg-icon';

import { IconWrapper } from '@/core/components/icon/icon-wrapper';
import SVGIcon from '@/core/components/icon/svg-icon';
import color from '@/core/features/styles/color';

export default function MedalIcon(props: SVGIconProps & IconWrapperProps) {
    return (
        <IconWrapper {...props}>
            <SVGIcon
                {...props}
                viewBox={'0 0 100 100'}
            >
                <ellipse
                    cx={'50'}
                    cy={'50'}
                    fill={color('alto')}
                    rx={'50'}
                    ry={'50'}
                    vectorEffect={'non-scaling-stroke'}
                />
                <path
                    d={'M 0 50 C 0 77.614 22.386 100 50 100 L 50 0 C 22.386 0 0 22.386 0 50 Z'}
                    fill={props.color}
                    vectorEffect={'non-scaling-stroke'}
                />
            </SVGIcon>
        </IconWrapper>
    );
}
