import { dataLayerPush } from '@/core/features/tracking/google-data-layer';

export function trackSpotResultClick(spotTitle: string, { isMap }: { isMap: boolean }) {
    dataLayerPush({
        element: 'spot',
        event: 'click',
        map: isMap ? 'yes' : 'no',
        spot: spotTitle,
    });
}
