import React from 'react';

import { bemModule } from '@/core/utils/bem-classname';

import styles from './inline-overlay.module.scss';

const bem = bemModule(styles);

type InlineOverlayProps = React.PropsWithChildren<{
    isOpen: boolean;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    overlay: React.ReactNode;
    reserveOverlaySpace: boolean; //always renders invisible overlay, no lazy loading possible
}>;

export default function InlineOverlay({ children, isOpen, onClick, overlay, reserveOverlaySpace }: InlineOverlayProps) {
    return (
        <div
            className={styles.inlineOverlay}
            onClick={onClick}
        >
            <div className={styles.content}>{children}</div>
            {(isOpen || reserveOverlaySpace) && <div className={bem(styles.overlay, { show: isOpen })}>{overlay}</div>}
        </div>
    );
}
