import type { RatingType } from '@/core/features/rating/rating-type';

import dynamic from 'next/dynamic';

import RatingIconContent from './rating-icon-content';

const Tooltip = dynamic(() => import('@/core/features/tooltip/tooltip'));
const TooltipTextBox = dynamic(() => import('@/core/features/tooltip/tooltip-text-box'));

export type RatingIconProps = RatingType & {
    tooltipText?: string;
};

export default function Rating({ color, rating, size, tooltipText, type }: RatingIconProps) {
    if (!tooltipText) {
        return (
            <RatingIconContent
                color={color}
                rating={rating}
                size={size}
                type={type}
            />
        );
    }
    return (
        <Tooltip
            ariaLabel={`Bewertung-${rating}`}
            content={
                <TooltipTextBox
                    htmlText={tooltipText}
                    htmlTitle={null}
                />
            }
            tippyConfig={{ offset: [0, 10], placement: 'bottom' }}
        >
            <RatingIconContent
                color={color}
                rating={rating}
                size={size}
                type={type}
            />
        </Tooltip>
    );
}
