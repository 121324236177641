import type { IconWrapperProps } from '@/core/components/icon/icon-wrapper';
import type { SVGIconProps } from '@/core/components/icon/svg-icon';

import { IconWrapper } from '@/core/components/icon/icon-wrapper';
import SVGIcon from '@/core/components/icon/svg-icon';
import color from '@/core/features/styles/color';

export default function StarHalfIcon(props: SVGIconProps & IconWrapperProps) {
    return (
        <IconWrapper {...props}>
            <SVGIcon
                {...props}
                viewBox={'0 0 100 100'}
            >
                <polygon
                    fill={color('alto')}
                    points={'50 0 35 33.6 0 38.2 25.7 63.6 19.1 100 50 82.1 80.9 100 74.3 63.6 100 38.2 65 33.6'}
                    vectorEffect={'non-scaling-stroke'}
                ></polygon>
                <polygon
                    fill={props.color}
                    points={'50 0 35 33.6 0 38.2 25.7 63.6 19.1 100 50 82.1'}
                    vectorEffect={'non-scaling-stroke'}
                ></polygon>
            </SVGIcon>
        </IconWrapper>
    );
}
