import React from 'react';

import { WindowCloseThinIcon } from '@/assets/icons/window-close-thin';
import { Button } from '@/core/features';
import color from '@/core/features/styles/color';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './tooltip-content-box.module.scss';

const bem = bemModule(styles);

export type TooltipContentBoxProps = {
    content: React.ReactNode | null;
    handleClose?: () => void;
    hasCloseButton?: boolean;
    isCloseable?: boolean;
};

export default function TooltipContentBox({
    content,
    handleClose,
    hasCloseButton = false,
    isCloseable,
}: TooltipContentBoxProps) {
    const closeButtonRef = React.useRef(null);
    return (
        <div className={bem(styles.tooltipContentContainer, { hasCloseButton })}>
            {isCloseable && (
                <div
                    className={styles.tooltipContainer}
                    onClick={handleClose}
                >
                    <button
                        className={styles.modalCloseButton}
                        data-qa-id={'qa-tooltip-close-button'}
                        ref={closeButtonRef}
                    >
                        <WindowCloseThinIcon
                            color={color('nobel')}
                            height={15}
                            width={15}
                        />
                    </button>
                </div>
            )}
            <div>{content}</div>
            {hasCloseButton && (
                <div className={styles.buttonContainer}>
                    <Button
                        fontSize={14}
                        height={40}
                        onClick={handleClose}
                        title={'übernehmen'}
                        width={122}
                    />
                </div>
            )}
        </div>
    );
}
