import type { IMapSchoolTile } from '@/features/map/map-data-v5/map-types';

import MapTileWrapper from '@/features/map/map-components/map-tiles/map-tile-wrapper';

type MapSchoolTileProps = IMapSchoolTile;

export default function MapSchoolTileDesktop({
    badge,
    htmlName,
    iconUrl,
    image,
    linkUrl,
    offerList,
    schoolType,
    subtitle,
}: MapSchoolTileProps) {
    const hasOffer = offerList !== null && offerList.length > 0;

    return (
        <MapTileWrapper
            badge={badge}
            cursorStyle={schoolType === 'bookable' ? 'pointer' : undefined}
            height={'auto'}
            iconUrl={iconUrl}
            image={image}
            isDesktopView={true}
            linkType={'internal'}
            linkUrl={schoolType === 'bookable' ? linkUrl : null}
            minHeight={177}
            qaId={'qa-map-school-tile-desktop'}
            subtitle={subtitle}
            title={htmlName}
        >
            {hasOffer && schoolType === 'bookable' && (
                <div className={'height-100 flex-column justify-end padding-15'}>
                    <div
                        className={'flex-column gap-10'}
                        //eslint-disable-next-line no-inline-styles/no-inline-styles
                        style={{ height: '71px' }}
                    >
                        {offerList &&
                            offerList.map((offer) => (
                                <div
                                    className={'flex gap-5 font-size-14 line-height-17 width-100'}
                                    key={offer.name}
                                >
                                    <div className={'ellipsis'}>{offer.name}</div>
                                    <div className={'shrink-0'}>{offer.price}</div>
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </MapTileWrapper>
    );
}
