import type { RatingType } from '@/core/features/rating/rating-type';

import MedalIcon from '@/assets/icons/medal';
import MedalHalfIcon from '@/assets/icons/medal-half';
import StarIcon from '@/assets/icons/star';
import StarHalfIcon from '@/assets/icons/star-half';
import color from '@/core/features/styles/color';

export default function RatingIconContent({
    color: iconColor = color('selectiveYellow'),
    rating,
    size = 13,
    type = 'stars',
}: RatingType) {
    const fullIconCount = Math.round(rating * 2) / 2;
    const renderHalfIcon = !!(fullIconCount % 1);

    return (
        <div
            className={'flex'}
            data-qa-id={'qa-rating-selector'}
            style={{
                // eslint-disable-next-line no-inline-styles/no-inline-styles
                gap: '1px',
            }}
        >
            {Array.from({ length: fullIconCount }).map((_, index) => (
                <div key={index}>
                    {type === 'stars' ? (
                        <StarIcon
                            color={iconColor}
                            height={size}
                            width={size}
                        />
                    ) : (
                        <MedalIcon
                            color={iconColor}
                            height={size}
                            width={size}
                        />
                    )}
                </div>
            ))}
            {renderHalfIcon && (
                <div>
                    {type === 'stars' ? (
                        <StarHalfIcon
                            color={iconColor}
                            height={size}
                            width={size}
                        />
                    ) : (
                        <MedalHalfIcon
                            color={iconColor}
                            height={size}
                            width={size}
                        />
                    )}
                </div>
            )}
        </div>
    );
}
