import type { IconWrapperProps } from '@/core/components/icon/icon-wrapper';
import type { SVGIconProps } from '@/core/components/icon/svg-icon';

import { IconWrapper } from '@/core/components/icon/icon-wrapper';
import SVGIcon from '@/core/components/icon/svg-icon';

export default function MedalIcon(props: SVGIconProps & IconWrapperProps) {
    return (
        <IconWrapper {...props}>
            <SVGIcon
                {...props}
                viewBox={'0 0 100 100'}
            >
                <ellipse
                    cx={'50'}
                    cy={'50'}
                    rx={'50'}
                    ry={'50'}
                    vectorEffect={'non-scaling-stroke'}
                />
            </SVGIcon>
        </IconWrapper>
    );
}
