import { IconImage } from '@/core/features';

import styles from './map-tile-icon.module.scss';

export default function MapTileIcon({ iconSize, iconUrl }: { iconSize: number; iconUrl: string }) {
    return (
        <span className={styles.mapTileIconWrapper}>
            <IconImage
                alt={'map-tile-icon'}
                height={iconSize}
                url={iconUrl}
                width={iconSize}
            />
        </span>
    );
}
